.loader {
  color: #ffe600;
  fill: none;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100vh;
}

.loader svg {
  width: 50vw;
  max-width: 200px; /* Prevents SVG from getting too large */
  height: auto; /* Adjust height automatically */
}

.loader-text {
  margin-top: 1rem;
  font-size: clamp(1rem, 2.5vw, 1.5rem); /* Responsive font size */
  color: #333;
  text-align: center;
}

@media (max-width: 600px) {
  .loader svg {
    width: 70vw;
  }

  .loader-text {
    font-size: 0.9rem;
  }
}

@media (min-width: 1200px) {
  .loader svg {
    width: 20vw;
  }

  .loader-text {
    font-size: 1.2rem;
  }
}
