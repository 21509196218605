body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  perspective: 1000px; /* Enable 3D perspective */
}

.card .MuiCard-root {
  transition: transform 0.3s; /* Animation transition */
}

.card:hover .MuiCard-root {
  transform: rotateY(10deg) rotateX(5deg); /* Rotate on hover for 3D effect */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}
